import i18n from "@/i18n";

export function getConvertedUnitNameMap() {
  return [
    { unit: "unit", name: i18n.t("common.unitNames.unit") },
    { unit: "case", name: i18n.t("common.unitNames.case") },
    { unit: "pack", name: i18n.t("common.unitNames.pack") },
    { unit: "bottle", name: i18n.t("common.unitNames.bottle") },
    { unit: "box", name: i18n.t("common.unitNames.box") },
    { unit: "cycle", name: i18n.t("common.unitNames.cycle") },
    { unit: "bag", name: i18n.t("common.unitNames.bag") },
    { unit: "pallet", name: i18n.t("common.unitNames.pallet") },
  ];
}

export function getWeightUnitNameMap() {
  return [
    { unit: "kg", name: i18n.t("tiles.quantity.totalWeight.units.kilograms") },
    { unit: "g", name: i18n.t("tiles.quantity.totalWeight.units.grams") },
    { unit: "mt", name: i18n.t("tiles.quantity.totalWeight.units.tons") },
    { unit: "lb", name: i18n.t("tiles.quantity.totalWeight.units.pounds") },
    { unit: "oz", name: i18n.t("tiles.quantity.totalWeight.units.ounces") },
    { unit: "t", name: i18n.t("tiles.quantity.totalWeight.units.imperialTons") },
  ];
}

export function getVolumeUnitNameMap() {
  return [
    { unit: "l", name: i18n.t("tiles.quantity.totalVolume.units.liters") },
    { unit: "ml", name: i18n.t("tiles.quantity.totalVolume.units.milliliters") },
    { unit: "hl", name: i18n.t("tiles.quantity.totalVolume.units.hectoliters") },
    { unit: "fl-oz", name: i18n.t("tiles.quantity.totalVolume.units.fluidOunces") },
    { unit: "gal", name: i18n.t("tiles.quantity.totalVolume.units.gallons") },
    { unit: "gal-uk", name: i18n.t("tiles.quantity.totalVolume.units.imperialGallons") },
  ];
}

export function getLengthUnitNameMap() {
  return [
    { unit: "m", name: i18n.t("tiles.quantity.totalLength.units.meters") },
    { unit: "km", name: i18n.t("tiles.quantity.totalLength.units.kilometers") },
    { unit: "mm", name: i18n.t("tiles.quantity.totalLength.units.millimeters") },
    { unit: "in", name: i18n.t("tiles.quantity.totalLength.units.inches") },
    { unit: "ft", name: i18n.t("tiles.quantity.totalLength.units.feet") },
    { unit: "mi", name: i18n.t("tiles.quantity.totalLength.units.miles") },
    { unit: "yd", name: i18n.t("tiles.quantity.totalLength.units.yards") },
  ];
}

export function getUnitName(unit) {
  const convertedUnitName = getConvertedUnitNameMap().find((entry) => entry.unit === unit);
  if (convertedUnitName) return convertedUnitName;
  const weightUnitName = getWeightUnitNameMap().find((entry) => entry.unit === unit);
  if (weightUnitName) return weightUnitName;
  const lengthUnitName = getLengthUnitNameMap().find((entry) => entry.unit === unit);
  if (lengthUnitName) return lengthUnitName;
  const volumeUnitName = getVolumeUnitNameMap().find((entry) => entry.unit === unit);
  if (volumeUnitName) return volumeUnitName;
  return { unit: unit, name: unit };
}

export function getConvertedUnitNameTiles(tile, isAuthorized, isConfigSelected, selectKpi, defaultConfig = {}) {
  const convertedUnitNames = getConvertedUnitNameMap();
  return convertedUnitNames.map((unitNameMap) => {
    const name = unitNameMap.name.charAt(0).toUpperCase() + unitNameMap.name.slice(1);
    const config = { ...defaultConfig, product_unit: unitNameMap.unit };
    return {
      name: name,
      isLocked: !isAuthorized(tile),
      selected: isConfigSelected(tile, config),
      action: () => selectKpi(tile, config),
    };
  });
}

export function getWeightUnitNameTiles(tile, isConfigSelected, selectKpi, defaultConfig = {}) {
  const weightUnitNames = getWeightUnitNameMap();

  return weightUnitNames.map((unitNameMap) => {
    const config = { ...defaultConfig, product_unit: unitNameMap.unit };
    return {
      name: unitNameMap.name,
      selected: isConfigSelected(tile, config),
      action: () => selectKpi(tile, config),
    };
  });
}

export function getLengthUnitNameTiles(tile, isConfigSelected, selectKpi, defaultConfig = {}) {
  const lengthUnitNames = getLengthUnitNameMap();

  return lengthUnitNames.map((unitNameMap) => {
    const config = { ...defaultConfig, product_unit: unitNameMap.unit };
    return {
      name: unitNameMap.name,
      selected: isConfigSelected(tile, config),
      action: () => selectKpi(tile, config),
    };
  });
}

export function getVolumeUnitNameTiles(tile, isConfigSelected, selectKpi, defaultConfig = {}) {
  const volumeUnitNames = getVolumeUnitNameMap();

  return volumeUnitNames.map((unitNameMap) => {
    const config = { ...defaultConfig, product_unit: unitNameMap.unit };
    return {
      name: unitNameMap.name,
      selected: isConfigSelected(tile, config),
      action: () => selectKpi(tile, config),
    };
  });
}
